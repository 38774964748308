// @import "../../../../assets/sass/base/global";
@import "../../../../assets/sass/base/variables";


.projects{
    margin-top: 107px;
  padding-bottom: 100px;
  //min-height: 100vh;
  }
.project_gallery{
  margin-bottom: 90px;
}
  
  .all_projects{
    font-size: $p4-fs;
    line-height: $p4-lh;
    font-weight: bold;
    display: flex;
  }
  .right_arrow{
    margin-left: 12px;
  }
  .all{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .navigate{
    display: flex;
  }
  .navigate_left{
    margin-right: 16px;
  }
  .projects_cards{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    margin-top: 40px;
    margin-bottom: 56px;
  }
  .project_card{
    width: 100%;
    margin-right: 20px;
  }
  .project_card_img{
    border-radius: 12px;
    height: 262px;
    object-fit: cover;
    background: aliceblue;
    width: 100%;
  }
  .project_card_info{
    padding: 21px 16px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    position: relative;
  }
  .project_card_info_head{
    font-size: $h7-fs;
    line-height: $h7-lh;
    margin: 16px 0;
    font-weight: bold;
    margin-top: 0;
  }
  .project_card_info_desc{
    font-size: $p4-fs;
    line-height: $p4-lh;
  }
  .circle{
    display: block;
    margin: 0 auto
  }
  .circle_projects{
    position: absolute;
    opacity: 0;
    transition: all 0.3s;
    left: 50%; transform: translateX(-50%);
  }
  .circle_projects{
    display: block;
    margin: 0 auto
  }
  .chevron_circle_projects{
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #fff;
    position: relative;
    top: 0px;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.16);
  }
  .project_card:hover{
    transition: 0.3s all;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.16);
    border-radius: 12px;
    .circle_projects{
      opacity: 1;
    }
  
  }
  
  .blogs_tab{
    display: flex;
    margin-left: auto;
    align-items: center;
    border: 1px solid #C6C8D3;
    box-sizing: border-box;
    border-radius: 12px;
    width: fit-content;
    margin-bottom: 30px;
  }
  .tab_item{
    background: transparent;
    box-shadow: none;
    border: none;
    outline: none;
    font-size: $p4-fs;
    line-height: $p4-lh;
    color: $link;
    opacity: 0.4;
    padding: 10px 30px;
    cursor: pointer;
  }
  .selected_tab{
    color: $link;
    opacity: 1;
  }
  .tab_divider{
    width: 1px;
    height: 30px;
    background:#C6C8D3;
  }
  
  .blogs_tab_mobile{
    display: none;
  }
  .select_tab{
    border: 1px solid #C6C8D3;
    box-sizing: border-box;
    border-radius: 12px;
    box-shadow: none;
    outline: none;
    background: none;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    margin-left: auto;
  }
  .select_name{
    margin-right: 32px;
  }
  @media (max-width: 768px) {
    .blogs_tab{
      display: none;
    }
    .blogs_tab_mobile{
      display: flex;
    }
    .projects_cards{
      display: block;
    }
  }
