$link: #1379BE;

$green: #25D366;
$green1: #D3F6E0;
$green--5: #F4FDF7;

$red: #EB6569;
$red1: #F9D1D2;
$red--5: #FDF3F3;

$white: #FFFFFF;

$black7: #000000;
$black6: #333333;
$black5: #666666;
$black4: #808080;
$black3: #B3B3B3;
$black2: #E6E6E6;
$black1: #F2F2F2;
$black--5: #F9F9F9;

// FONT SIZE
$h1-fs: 44px; $h1-lh:57.2px;
$h2-fs: 36px; $h2-lh:46.8px;
$h3-fs: 28px; $h3-lh:36.4px;
$h4-fs: 24px; $h4-lh:31.2px;
$h5-fs: 20px; $h5-lh:26px;
$h6-fs: 18px; $h6-lh:23.4px;
$h7-fs: 16px; $h7-lh:20.8px;

$p1-fs:22px; $p1-lh:33px;
$p2-fs:20px; $p2-lh:34px;
$p3-fs:18px; $p3-lh:30px;
$p4-fs:16px; $p4-lh:27px;
$p5-fs:14px; $p5-lh:23px;
//FONTS
//$l_regular : "Lora-Regular";
//$l_semi_bold : "Lora-SemiBold";
//$ws_regular : "WorkSans-Regular";
//$ws_semi_bold : "WorkSans-SemiBold";
$gt_medium : 'Gotham-Medium';
//RESPONSIVE
$mobile: 992px;
