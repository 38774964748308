@import "../../../../assets/sass/base/variables";
.footer{
  background: #0B0D17;
  padding: 60px 0;
}
.d-flex{
  display: flex !important;
}services_card


.footer_content{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.knexel_logo{
  margin-bottom: 20px;
}
.footer_col{
  width: 25%;
  color: white;
}
.knexel_desc{
  font-size: $p5-fs;
  margin-bottom: 30px;
  color: white;
}
.col_head{
  font-size: $h5-fs;
  line-height: $h5-lh;
  color: white;
  margin-bottom: 28px;
  margin-top: 0;
}
.col_navigate{
  font-size: $p5-fs;
  line-height: $p5-lh;
  margin-bottom: 12px;
  color: white;
  text-decoration: none;
  display: block;
}
.footer_divider{
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}
.hr{
  width: 49%;
}
.copyright{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.copyright_info{
  color: white;
  font-size: $p5-fs;
  line-height: $p5-lh;
}

.socials{
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
}
.social_icons{
  margin-right: 12px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .footer_content{
    flex-direction: column;
  }
  .footer_col{
    width: 100% !important;
  }
  .d_none_mobile{
    display: none;
  }
  .social_icons{
    margin-right: 12px;
  }
}
