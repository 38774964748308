// @import "../../../../assets/sass/base/global";
@import "../../../../assets/sass/base/variables";

.services{
  margin-top: 90px;
}

.services_heading{
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 90px;
}

.services_avatar{
  width: 30%;
  margin-right: 20px;
}
.services_jumbotron{
  width: 70%;
}
.services_img{
  width: 100%;
  object-fit: contain;
  background: transparent;
  height: 256px;
}

.services_header{
  font-size: $h4-fs;
  line-height: $h4-lh;
  margin-bottom: 16px;
  margin-top: 0;
}

.services_list{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  margin-bottom: 64px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.services_card{
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 4px 5px rgba(17, 64, 119, 0.15);
  background-size: cover;
  // background: url("../../../../assets/images/Asset4.jpg") no-repeat center;
  height: 300px;
  background-size: contain;
  width: 220px;
  transition: all 0.3s;
  border-radius: 28px;
}

.services_card_img{
  border-radius: 12px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  // margin-top: 30px;
}
.services_card_info{
  padding: 21px 16px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  position: relative;
}
.services_card_info_head{
  font-size: $h7-fs;
  line-height: $h7-lh;
  font-weight: bold;
  margin: 0 0 16px;
  text-align: center;
}
.services_card_info_desc{
  font-size: $p4-fs;
  line-height: $p4-lh;
  text-align: center;
  color: #92949F;
}
.circle{
  display: block;
  margin: 0 auto
}
.circle_projects{
  position: absolute;
  opacity: 0;
  transition: all 0.3s;
  left: 50%; transform: translateX(-50%);
}
.circle_projects{
  display: block;
  margin: 0 auto
}
.chevron_circle_projects{
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #fff;
  position: relative;
  top: 0px;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.16);
}
.services_card:hover{
  transition: 0.3s all;
  border-radius: 28px;
  box-shadow: 0px 3px 12px rgb(36 37 41 / 53%);
  transform: scale(1.05);
  .circle_projects{
    opacity: 1;
  }

}

.mobile_navigation{
  display: none;
}
.mobile_navigation_img{
  width: 42px;
  height: 42px;
}
.left{
  margin-right: 16px;
}
@media (max-width: 768px) {
  .mobile_navigation{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: end;
    margin-bottom: 30px;
  }
  .services_heading{
   flex-direction: column;
  }
  .services_avatar{
    width: 100%;
    margin-bottom: 60px;
  }
  .services_jumbotron{
    width: 100%;
  }
  .services_list{
   display: flex;
    width: 100%;
    overflow-x: scroll;
    height: 350px;
  }
  .services_card {
    min-width: 84%;
    margin-right: 16px;
    height: 324px;
  }
}
