.scale-on-hover{
    transition: .2s ease-in-out;
    &:hover{
        transform: scale(1.05);
    }
}

.scale-img-on-hover{
    img{
        transition: .2s ease-in-out;
    }
    &:hover{
        img{
            transform: scale(1.05);
        }
    }
}