// @import "../../../../assets/sass/base/global";
@import "../../../../assets/sass/base/variables";

.goals{
  margin-top: 90px;
}


.goals_head{
  display: flex;
  align-items: center;
}

.right_log{
  margin-right: 10px;
}
.goals_header{
  font-size: $h4-fs;
  line-height: $h4-lh;
  color:#0E3B5B;
  margin: 28px 0;
}

.goals_top, .goals_mid{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.goals_mid, .goals_btm{
  margin-top: 60px;
}
.top_left, .top_right{
  //width: 50%;
  margin-right: 32px;
}
.content_header{
  color: $link;
  font-size: $h7-fs;
  line-height: $h7-lh;
  margin-bottom: 12px;
}
.content_head{
  border-bottom: 1px solid #E3F8FF;
}
.content_data{
  font-size: $p4-fs;
  line-height: $p4-lh;
}
.values{
  background:#f8fcff;
  padding: 60px 0;
}

@media (max-width: 768px) {
  .goals_top, .goals_mid{
    grid-template-columns: 1fr
  }
  .top_left, .top_right{
    width: 100%;
    margin-right: 0;
  }
}
