@import "../../../../assets/sass/base/variables";

//BURGER MENU

.burger_menu_opened{
  width: 100vw;
  height: 100vh;
  background: #1379BE;
  position: fixed;
  z-index: 9999999999 !important;
  top: 0;
  left: -100vw;
  padding: 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: .3s ease-in-out;
  overflow: auto;
  &.active{
    left: 0px;
  }
  a{
    color: white!important;
  }
}
.burger_menu_item{
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  font-size: $p4-fs;
  line-height: $p4-lh;
  margin-bottom: 30px;
  cursor: pointer;
}

.flex{
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  font-size: $p4-fs;
  line-height: $p4-lh;
  width: 100%;
  border-bottom: 1px solid #fff;
  padding-bottom: 16px;
}
.menu_item_chevron_down{
  transform: rotate(180deg);
}
.burger_menu_list{
  padding: 0;
  margin: 0;
}
.close_menu{
  background: none;
  box-shadow: none;
  border: none;
  margin-bottom: 56px;
}

.burger_dropdown_open{
  border-bottom: 1px solid #fff;
  padding-bottom: 16px;
  .opened_menu_items{
    display: block!important;
  }
}

.burger_dropdown{
  flex-direction: column !important;
  .opened_menu_items{
    display: none;
  }
}
.menu_route{
  margin-right: auto;
}
.opened_menu_items{
  padding-left: 24px;
  list-style: none;
  width: 100%;
  margin: 12px 0;
  li{
    margin: 12px 0;
  }
}

.burger_socials{
  display: flex;
  align-items: center;
  justify-content: center;
  //position: absolute;
  bottom: 0;
  margin-top: 30px;
  //margin-bottom: 30px;
  width: 100%;
}
.social_icons{
  margin-right: 12px;
  cursor: pointer;
}


//BURGER MENU END

.light_navbar{
    height: 90px;
    background: #fff;
    //width: 100%;
    box-shadow: 0px 4px 30px rgba(85, 85, 85, 0.1);
    position: sticky;
    top: 0;
    z-index: 1000;
    .home_logo{
      img{
        &:last-child{
          display: none;
        }
      }
    }
  }
  .navbar_top_bar{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .home_logo{
    width: 20%;
    cursor: pointer;
  }
  .h-100{
    height: 100%;
  }
  .navbar_main_menu{
    width: 60%;
  }
  .home_language{
    width: 20%;
    display: flex;
    justify-content: end;
  }
  .navbar_menu_items{
    display: flex;
    align-items: center;
    color: #0B0D17;
  }
  .navbar_menu_item{
    list-style: none;
    color: #0B0D17;
    font-size: $h7-fs;
    margin-right: 32px;
    a{
      color: #0B0D17 !important;
    }
  }
  .menu_item_chevron{
    margin-left: 8px;
    width: 14px;
  }
  .menu_dropdown{
    display: flex;
    position: relative;
    align-items: center;
    width: 112px;
    cursor: pointer;
  }
  .navbar_language{
    width: 20%;
    display: flex;
    justify-content: end;
    visibility: hidden;
  }

  .menu_opened{
    visibility: hidden;
    //transition: 0.3s all;
    position: absolute;
    top: 54px;
  }

  .multi_menu{
    // visibility: hidden;
    display: none;
    //transition: 0.3s all;
  }
  .menu_dropdown.active{
    .menu_opened{
      visibility: visible;

    }
  }

  .selected_menu:hover{

  }
  .secondary_banner{
    height: 320px;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 0;
  }
  .sm_banner_p{
    font-size: $p4-fs;
    line-height: $p4-lh;
    color: white;
    width: 70%;
    text-align: center;
    margin-top: 0;
  }
  .tab_item{
    background: transparent;
    box-shadow: none;
    border: none;
    outline: none;
    font-size: $p4-fs;
    line-height: $p4-lh;
    color: $link;
    opacity: 0.4;
    padding: 10px 30px;
    cursor: pointer;
  }
  .selected_tab{
    color: $link;
    opacity: 1;
  }
  .tab_divider{
    width: 1px;
    height: 30px;
    background:#C6C8D3;
  }
.burger_menu{
  display: none;
}
  @media (max-width: 768px) {
    .burger_menu{
      display: block;
      cursor: pointer;
    }
    .navbar_main_menu{
      display: none;
    }
    .home_logo {
      /* width: 7%; */
      margin: 0 auto;
      img{
        width: 90px !important;
      }
    }
  }


  .has-sub{
    .multi_menu{
      position: absolute;
      top: 0px;
      right: 0px;
      transform: translateX(100%);
      display: none;
    }
    &:hover{
      .multi_menu{
        display: block;
      }
    }
  }
