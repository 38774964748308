//@font-face {
//    font-family: $l_regular;
//    src: url(../fonts/Lora/Lora-Regular.ttf);
//}
//
//@font-face {
//    font-family: $l_semi_bold;
//    src: url(../fonts/Lora/Lora-SemiBold.ttf);
//}
//
//@font-face {
//    font-family: $ws_regular;
//    src: url(../fonts/Work_Sans/WorkSans-Regular.ttf);
//}
//
@font-face {
    font-family: $gt_medium;
    src: url(../../fonts/Gotham-Medium.woff);
}
