// @import "../../../../assets/sass/base/global";
@import "../../../../assets/sass/base/variables";

.home_banner{
    min-height: 100vh;
    width: 100%;
    background-size: cover;
    padding-top: 90px;
  display: flex;
  align-items: center;
  }
  
  .home_navbar{
    height: 90px;
    background: transparent;
    position: relative;
    z-index: 9999;
    *{
      color: #FFFFFF;
    }
    a{
      color: #FFFFFF!important;
    }

    .menu_nav {
      a{
        color: #0B0D17 !important;
      }
    }

    .home_logo{
      img{
        &:first-child{
          display: none;
        }
      }
    }
    .menu_item_chevron, .burger_menu{
      filter: brightness(0) invert(1)!important;
    }
  }
  .home{
    margin-top: -90px;
  }
  
  .home_top_bar{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .home_logo{
    width: 20%;
  }
  .home_main_menu{
    width: 60%;
  }
  .home_language{
    width: 20%;
    display: flex;
    justify-content: end;
  }
  .menu_items{
    display: flex;
    align-items: center;
  }
  .menu_item{
    list-style: none;
    color: $white;
    font-size: $h7-fs;
    margin-right: 32px;
  }
  .menu_item_chevron{
    margin-left: 8px;
    width: 14px;
  }
  .menu_dropdown{
    display: flex;
    align-items: center;
  }
  
  .home_jumbotron{
    height: calc(100% - 90px);
    display: flex;
  }
  .home_jumbotron_content{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .jumbotron_heading{
    font-size: $h1-fs;
    line-height: $h1-lh;
    color: $white;
    margin-bottom: 30px;
  }
  .jumbotron_action_part, .jumbotron_info_part{
    width: 40%;
  }
  .jumbotron_p{
    font-size: $p4-fs;
    line-height: $p4-lh;
    color: $white;
    margin-bottom: 30px;
  }
  
  .info_content{
    width: 100%;
    margin-left: auto;
    background-size: contain;
    background-repeat: no-repeat;
    height: 320px;
    display: flex;
    flex-direction: column;
    //justify-content: space-between;
  }
  .content_top, .content_bottom{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .content_top, .content_bottom {
    height: 160px;
  }
  .top_item, .bottom_item{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .menu_opened_content{
    padding: 0;
  }
  .menu_opened{
    width: max-content;
    background: #FFFFFF;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 12px 12px;
    z-index: 1000;
  }
  .multi_menu{
    width: max-content;
    background: #F6FDFF;
    box-shadow: 0 4px 40px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 12px 0;
    z-index: 1000;
  }
  .menu_nav{
    height: 42px;
   
    color: #0B0D17;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    //transition: 0.3s all;
    a{
      padding: 0 30px;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      &.selected_menu{
        background: $link;
        color: white!important;
        cursor: pointer;
        //transition: 0.3s all;
      }
    }
  }
  .menu_nav:last-child{
    padding-bottom: 16px;
  }
  .menu_nav:first-child{
    padding-top: 16px;
  }
  .multi_menu_nav{
    height: 42px;
    color: #0B0D17;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition: 0s;
    a{
      padding: 0 30px;
    }
  }
  .multi_menu_nav:last-child{
    padding-bottom: 16px;
  }
  .multi_menu_nav:first-child{
    padding-top: 16px;
  }
  .multi_menu_nav:hover{
    color: $link;
  }
  
  .selected_chevron{
    transform: rotate(-90deg);
  }
  .item_head{
    font-size: $h2-fs;
    line-height: $h2-lh;
    margin: 0;
    color: $white;
  }
  .chevron{
    width: 23px;
    height: 23px;
    position: relative;
    left: 4px;
  }
  .item_desc{
    font-size: $p4-fs;
    line-height: $p4-lh;
    color: $white;
    margin: 0;
    text-align: center;
  }
  .h-100{
    height: 100%;
  }
  #services{
    .services{
      width: 100%;
      background-size: contain;
    }
  }
  .services_items{
    width: 45%;
    margin-left: auto;
  }
  .services_content{
    display: flex;
    justify-content: space-between;
    padding-top: 120px;
  }
  .section_heading{
    font-size: $h2-fs;
    line-height: $h2-lh;
    margin-bottom: 16px;
    color: $link;
  }
  .services_p{
  font-size: $p4-fs;
    line-height: $p4-lh;
  }
  .services_desc{
    width: 40%;
  }
  .services_items_top, .services_items_bottom{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px
  }
  .digital_consulting, .solution_eng, .tech_inf, .it_ops{
    width: 100%;
    height: 256px;
    margin-bottom: 11px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 4px 5px rgba(17, 64, 119, 0.15);
    justify-content: center;
    transition: all 0.3s;
  }
  .home_col_head{
    font-size: $h6-fs;
    line-height: $h6-lh;
    font-weight: bold;
    text-align: center;
  }
  .col_desc{
    font-size: $p4-fs;
    line-height: $p4-lh;
    text-align: center;
    color: #92949F;
    margin: 0;
  }
  .chevron_circle{
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #fff;
    position: relative;
    top: 130px;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.16);
  }
  .rec .rec-arrow{
    background: #fff !important;
    color: $link !important;
    border: none !important;
    box-shadow: none !important;
  }

  .circle{
    position: absolute;
    opacity: 0;
    transition: all 0.3s;
  }
  .digital_consulting:hover,  .solution_eng:hover, .tech_inf:hover, .it_ops:hover{
    background: $link;
    .home_col_head{
      color: $white;
    }
    .col_desc{
      color: $white;
    }
    .circle{
      position: absolute;
      opacity: 1;
      transition: all 0.3s;
    }
  }
  .video{
    margin-top: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .tech_head{
    margin-bottom: 30px;
  }
  .section_p{
    font-size: $p4-fs;
    line-height: $p4-lh;
    margin: 0;
    margin-bottom: 16px;
  }
  .tech_p{
    margin-bottom: 53px;
  }
  
  .projects{
  margin-top: 107px;
  }
  
  .all_projects{
    font-size: $p4-fs;
    line-height: $p4-lh;
    font-weight: bold;
    display: flex;
  }
  .right_arrow{
    margin-left: 12px;
  }
  .all{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .navigate{
    display: flex;
  }
  .navigate_left{
    margin-right: 16px;
  }
  .projects_cards{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    margin-top: 40px;
  }
  .project_card{
    width: 100%;
    margin-right: 20px;
    margin-bottom: 50px;
  }
  .project_card_img{
    border-radius: 12px;
    height: 262px;
    object-fit: cover;
    background: aliceblue;
    width: 100%;
  }
  .project_card_info{
    padding: 21px 16px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    position: relative;
  }
  .project_card_info_head{
    font-size: $h7-fs;
    line-height: $h7-lh;
    margin: 16px 0;
    font-weight: bold;
    margin-top: 0;
  }
  .project_card_info_desc{
    font-size: $p4-fs;
    line-height: $p4-lh;
  }
  .circle{
    display: block;
    margin: 0 auto
  }
  .circle_projects{
    position: absolute;
    opacity: 0;
    transition: all 0.3s;
    left: 50%; transform: translateX(-50%);
  }
  .circle_projects{
    display: block;
    margin: 0 auto
  }
  .chevron_circle_projects{
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #fff;
    position: relative;
    top: 0px;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.16);
  }
  .project_card:hover{
    transition: 0.3s all;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.16);
    border-radius: 12px;
    .circle_projects{
      opacity: 1;
    }
  
  }
  
  
  .parallax {
    /* The image used */
    height: 430px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 120px;
  
  }
  .parallax_head{
    font-size: $h2-fs;
    line-height: $h2-lh;
    color: #ffffff;
    margin-bottom: 30px;
  }
  .parallax_p{
    font-size: $p4-fs;
    line-height: $p4-lh;
    color: #ffffff;
    margin-bottom: 30px;
  }
  .parallax_content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .button_more{
    padding: 10px 36px;
    font-size: $p4-fs;
    line-height: $p4-lh;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    box-shadow: none;
    border-radius: 5px;
    background: #fff;
  }
  .news{
    margin-top: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .news_cards{
    display: flex;
    align-items: center;
  }
  .news_card{
    width: 33%;
    margin-right: 20px;
  }
  .card_img{
    height: 250px;
    object-fit: cover;
    background: aliceblue;
    width: 100%;
  }
  
  
  .news_card_info{
     padding: 16px;
     border-bottom-left-radius: 12px;
     border-bottom-right-radius: 12px;
     position: relative;
   }
  .news_card_info_head{
    font-size: $h7-fs;
    line-height: $h7-lh;
    margin: 16px 0;
    font-weight: bold;
    margin-top: 0;
  }
  .news_card_info_desc{
    font-size: $p4-fs;
    line-height: $p4-lh;
    margin-bottom: 16px;
    margin-top: 0;
  }
  .news_card_date{
    font-size: $p5-fs;
    line-height: $p5-lh;
    color: $link;
    margin-bottom: 16px;
    margin-top: 0;
  }
  .card_navigate{
    color: $link;
    font-size: $p4-fs;
    line-height: $p4-lh;
    font-weight: bold;
  }
  .new_card{
    margin-right: 20px;
    position: relative;
  }
  .new_card:hover{
    transition: 0.3s all;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.16);
    border-radius: 12px;
  }
  .new_badge{
    position: absolute;
  }
  .badge_view{
    position: relative;
    top: 16px;
    left: 20px;
    border-radius: 28px;
    padding: 4px 30px;
    font-size: $p5-fs;
    line-height: $p5-lh;
    background: $link;
    color: white;
  }
  .partners{
    margin-top: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .appoint{
    background-size: cover;
    height: 370px;
    margin-top: 120px;
  }
  
  .appoint_content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }
  
  .appoint_info, .appoint_form{
    width: 45%;
  }
  .appoint_head{
    font-size: $h2-fs;
    line-height: $h2-lh;
    margin-bottom: 30px;
    color: white;
  }
  .appoint_desc{
    font-size: $p4-fs;
    line-height: $p4-lh;
    color: white;
  }
  .form_name_email{
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 20px;
  }
  .form_input{
    height: 47px;
    border-radius: 6px;
    padding-left: 10px;
  }
  .form_input::placeholder{
    color: #1379BE;
    font-size: $p4-fs;
    opacity: 0.4;
  }
  .form_name{
    width: 55%;
    margin-right: 20px;
  }
  .form_email{
    width: 45%;
  }
  .number_submit{
    display: flex;
    width: 100%;
    align-items: center;
  }
  .form_number{
    width: 75%;
    margin-right: 20px;
  }
  .submit{
    padding: 10px 36px;
    background: #1379BE;
    box-shadow: 3px 2px 28px rgba(101, 200, 255, 0.35);
    border-radius: 5px;
    color: white;
    font-size: $p4-fs;
    line-height: $p4-lh;
    border:none
  }
  .footer{
    background: #0B0D17;
    padding: 60px 0;
  }
  .footer_content{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .knexel_logo{
    margin-bottom: 20px;
  }
  .footer_col{
    width: 25%;
    color: white;
  }
  .knexel_desc{
    font-size: $p5-fs;
    margin-bottom: 30px;
    color: white;
  }
  .home_col_head{
    font-size: $h5-fs;
    line-height: $h5-lh;
    //color: white;
    margin-bottom: 28px;
    margin-top: 0;
  }
  .col_navigate{
    font-size: $p5-fs;
    line-height: $p5-lh;
    margin-bottom: 12px;
    color: white;
    text-decoration: none;
    display: block;
  }
  .footer_divider{
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }
  .hr{
    width: 49%;
  }
  .copyright{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .copyright_info{
    color: white;
    font-size: $p5-fs;
    line-height: $p5-lh;
  }
  .video_frame{
    width: 100%;
    height:520px
  }

  @media (max-width: 768px) {
    .home_action{
      margin: 0 0 60px 0 !important;
    }
    .jumbotron_heading{
      font-size: $h3-fs;
      line-height: $h3-lh;
      margin-bottom: 16px;
    }
    .home_jumbotron_content{
      flex-direction: column;
    }
    .jumbotron_action_part, .jumbotron_info_part{
      width: 100%;
    }
    .item_head{
      font-size: $h3-fs;
      line-height: $h3-lh;
    }
    .content_top, .content_bottom {
      height: 90px;
      padding: 10px 0;
    }
    .info_content {
      height: 180px;
          background-repeat: no-repeat;
          margin-bottom: 40px;
      }
  
    .services_content{
      padding-top: 0 !important;
    }
    .section_heading{
      font-size: $h5-fs;
      line-height: $h5-lh
    }
    .services_content {
    flex-direction: column;
    }
    .services_desc, .tech_p {
      width: 100%;
      font-size: $p4-fs;
      line-height: $p4-lh;
    }
    .services_items {
      width: 100%;
      margin-left: auto;
    }
    .services_items_top, .services_items_bottom {
      display: grid;
      grid-template-columns: 1fr;
    }
    .video_frame {
      width: 100%;
      height: 300px;
    }
    .parallax_head{
      font-size: $h5-fs;
      line-height: $h5-lh
    }
    .parallax_p,.appoint_head{
      font-size: $p4-fs;
      line-height: $p4-lh;
    }
    .appoint_content{
      flex-direction: column;
      justify-content: center;
    }
   .appoint_info, .appoint_form {
      width: 100%;
    }
    .appoint_head{
      //margin-top: 60px;
    }
    .appoint {
      background-size: cover;
      height: 400px;
      margin-top: 60px;
    }
  }
