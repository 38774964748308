@import "../../../../assets/sass/base/variables";
.secondary_banner{
  height: 320px;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0;
}
.sm_banner_p{
  font-size: $p4-fs;
  line-height: $p4-lh;
  color: white;
  width: 70%;
  text-align: center;
  margin-top: 0;
}

@media (max-width: 768px) {
  .sm_banner_p {
    width: 90%;
  }
  .sm_banner_h2 {
    font-size: 20px;
  }
  .secondary_banner {
    height: 247px;
  }
}
