.jumbotron-sc{
    .jumbotron{
        position: relative;
        margin-left: -15px;
        margin-right: -15px;
        .img-wrapper{
            height: 304px;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .content{
            padding: 24px 15px;
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            background: linear-gradient(360deg, #000000 -6.74%, rgba(0, 0, 0, 0) 100%);
            *{
                color: $white;
            }
            h1{
                // font-family: $l_regular;
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 32px;
            }
            .btn{
                display: none;
            }
        }
    }
}

@media only all and (min-width: $mobile) {
    .jumbotron-sc{
        padding-top: 48px;
        padding-bottom: 48px;
        background: linear-gradient(180deg, $white 0%, #F6F5F5 100%);
        .jumbotron{
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 40px;
            margin-left: 0px;
            margin-right: 0px;
            .img-wrapper{
                border-radius: 8px;
                overflow: hidden;
            }
            .content{
                position: static;
                background: transparent;
                width: auto;
                height: auto;
                padding: 0px;
                align-items: center;
                h1{
                    color: $black6;
                    font-weight: 600;
                    font-size: 32px;
                    line-height: 48px;
                }
                p{
                    color: $black5;
                }
                .btn{
                    display: block;
                }
            }
        }
    }
}