.tab-menu{
    &-heads{
        box-shadow: inset 0px -1px 0px $black2;
        &-item{
            position: relative;
            padding-top: 8px;
            padding-bottom: 8px;
            span{
                color: $black4;
                transition: .2s ease-in-out;
                &::after{
                    content: '';
                    position: absolute;
                    bottom: -11px;
                    left: 0px;
                    width: 100%;
                    height: 2px;
                    background-color: transparent;
                }
            }
            &.active{
                span{
                    position: relative;
                    color: $black7;
                    &::after{
                        background-color: $black7;
                    }
                }
            }
        }
    }
    &-body{
        &-item{
            min-height: 120px;
            display: none;
            opacity: 0;
            width: 0px;
            overflow: hidden;
            transition: .2s ease-in-out;
            opacity: 0;
            &.active{
                display: block;
                opacity: 1;
                width: 100%;
            }
            span{
                display: block;
            }
        }
    }
}