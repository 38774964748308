.accordion{
    &-item{
        &-body{
            height: 0px;
            overflow: hidden;
            transition: .2s ease-in-out;
        }
        &.active{
            &-body{
                height: 336px;
            }
        }
        .accordion-head{
            position: relative;
            padding-top: 8px;
            padding-bottom: 8px;
            box-shadow: inset 0px -1px 0px $black2;
            span{
                color: $black4;
                transition: .2s ease-in-out;
                &::after{
                    content: '';
                    position: absolute;
                    bottom: -11px;
                    left: 0px;
                    width: 100%;
                    height: 2px;
                    background-color: transparent;
                }
            }
            i{
                position: absolute;
                top: 50%;
                right: 2px;
                transform: translateY(-50%);
                font-size: 18px;
                transition: .2s ease-in-out;
                opacity: 0;
                &.ph-plus{
                    opacity: 1;
                }
            }
        }
    }
}

.accordion-item.active{
    .accordion-item-body{
        height: 336px;
    }
    .accordion-head{
        span{
            position: relative;
            color: $black7;
            &::after{
                background-color: $black7;
            }
        }
        i{
            &.ph-plus{
                opacity: 0;
            }
            &.ph-minus{
                opacity: 1;
            }
        }
    }
}