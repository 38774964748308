header{
    position: sticky;
    top: 0px;
    z-index: 3;
    box-shadow: inset 0px -1px 0px $black2;
    background-color: $white;
    .logo{
        width: 136px;
    }
    .icon-img-hover{
        width: 24px;
        height: 24px;
    }

    .search-outer-wrapper{
        .search-group{
            width: 100%;
            display: flex;
            align-items: center;
            .search-wrapper{
                width: 100%;
            }
            &>i{
                display: none;
                font-size: 24px;
            }
        }
        .search-dropdown {
            display: none;
            h2{
                color: $black4;
            }
            .search-card-list {
                .search-card-item{
                    padding: 8px 0px;
                    &:not(:last-child){
                        box-shadow: inset 0px -1px 0px $black--5;
                    }
                    .img-wrapper{
                        width: 48px;
                        height: 48px;
                        // padding: 8px;
                        background-color: $black--5;
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                }
            }
        }
        &.is-search-active{
            .search-dropdown {
                animation: fadeIn .2s;
                display: block;
            }
            .search-group{
                &>i{
                    display: block;
                }
            }
        }
    }
}

.cart-list-wrapper{
    position: relative;
    .cart-list-dropdown {
      display: none;
    //   opacity: 0;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 24px;
      padding-bottom: 48px;
      position: fixed;
      top: 143px;
      left: 0px;
      width: 100%;
      height: calc(100vh - 143px);
      overflow-y: auto;
      background-color: $white;
      z-index: 3;
    }
    &.is-cart-list-active{
        animation: fadeIn .2s;
        .cart-list-dropdown{
            display: block;
        }
        .icon-img-hover{
            img{
                &:first-child{
                    opacity: 0;
                }
                &:last-child{
                    opacity: 1;
                }
            }
        }
    }
  }

  .my-cart-sc{
    .my-cart-list {
        .my-cart-item{
            .close-btn{
                padding: 8px;
                width: 32px;
                height: 32px;
                font-size: 10px;
            }
            .info{
                p{
                    color: $black5;
                }
            }
            .cart-desc{
                color: $black5!important;
            }
            .img-wrapper{
                width: 64px;
                // height: 64px;
                // padding: 8px;
                background-color: $black--5;
                img{
                    // width: 100%;
                    // height: 100%;
                    object-fit: contain;
                }
            }
        }
        .content-group{
            width: calc(100% - 64px);
            display: flex;
            justify-content: space-between;
        }
    }
    input[type="number"]{
        appearance: textfield;
    }
    .price-list{
        box-shadow: inset 0px 1px 0px $black2;
    }
}

.is-cart-list-active{
    .cart-list-dropdown{
        display: block;
    }
    .icon-img-hover{
        img{
            &:first-child{
                opacity: 0;
            }
            &:last-child{
                opacity: 1;
            }
        }
    }
}

.desktop-show{
    display: none;
}

@media only all and (min-width: $mobile) {
    .desktop-hide{
        display: none;
    }
    .desktop-show{
        display: block;
    }
    header{
        .cart-list-dropdown.my-cart-sc{
            padding-top: 0px;
            padding-bottom: 16px;
            position: absolute;
            top: 40px;
            left: 100%;
            transform: translateX(-100%);
            width: 416px;
            height: 328px;
            border: 1px solid $black2;
            box-sizing: border-box;
            border-radius: 4px;
            .close-btn, .h5--semi-bold {
                display: none;
            }
            .my-cart-item{
                box-shadow: inset 0px -1px 0px $black2;
                padding-top: 16px;
                padding-bottom: 16px;
            }
            .content-group{
                padding-left: 8px;
                margin-bottom: 0px;
                width: calc(100% - 72px);
                .info-wrapper{
                    display: flex;
                    flex-wrap: wrap;
                    align-items: flex-start;
                    justify-content: space-between;
                    .info{
                        .p2{
                            font-size: 13px;
                            line-height: 24px;
                        }
                        .p3{
                            font-size: 13px;
                            line-height: 24px;
                        }
                    }
                }
            }
            .my-cart-list{
                .my-cart-item{
                    .img-wrapper{
                        width: 72px;
                        height: 72px;
                    }
                }
            }
            .btn-group{
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 24px;
            }
            .price-item{
                .p1--semi-bold{
                    font-size: 15px;
                    line-height: 24px;
                    // font-family: $ws_regular;
                }
            }
        }
    }
}

@-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}