// Import base styles
// @import "assets/sass/base/normalize";
// @import "assets/sass/base/reset";
@import "assets/sass/base/variables";
@import "assets/sass/base/fonts";
@import "assets/sass/base/mixins";
// @import "assets/sass/base/typography";
@import "assets/sass/single/flex";
@import "assets/sass/single/margin";
@import "assets/sass/single/padding";
@import "assets/sass/single/width";
@import "assets/sass/single/height";
@import "assets/sass/single/scale";
@import "assets/sass/single/display";
@import "assets/sass/single/cursor";
@import "assets/sass/single/position";
@import "assets/sass/base/global";

// Import components styles
@import "assets/sass/components/components";

// Import layouts styles
@import "assets/sass/layouts/header";
@import "assets/sass/layouts/footer";
@import "assets/sass/layouts/no-layout";
@import "assets/sass/layouts/profile-layout";

// Import pages styles
@import "assets/sass/pages/home";
@import "assets/sass/pages/not-found";

body, html{
    margin: 0;
    padding: 0;
    min-height: 100%;
  }
  .action_button{
    padding: 10px 36px;
    background: #1379BE;
    box-shadow: 3px 2px 28px rgba(101, 200, 255, 0.35);
    border-radius: 5px;
    border: none;
    font-size: $p4-fs;
    line-height: $p4-lh;
    color: $white;
    margin: 0px auto;
    margin-top: 40px;
  }
  .sm_banner_h2{
    font-size: $h3-fs;
    line-height: $h3-lh;
    color: white;
    margin-bottom: 20px;
  }
  button{
    cursor: pointer;
  }

  .rec-item-wrapper{
    padding-bottom: 48px;
}

.suscces-msg{
  display: flex;
  align-items: center;
  font-size: $p4-fs;
  line-height: $p4-lh;
  color: white;
  img{
    width: 24px;
    margin-right: 16px;
  }
}